import * as React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Contact from "../components/Contact/Contact"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"

const contact = () => {
  return (
    <>
      <Seo title="Contact" />
      <Layout>
        <SimpleBanner title="Contact">
          <StaticImage
            className="banner__image"
            src="https://images.ctfassets.net/5tx4i68nuag9/97bLHPSL8SpeH0XSoBFsY/21d53d66ede56751a059f7a109bb057d/22036821_m.jpg"
            alt="headerimage"
          />
        </SimpleBanner>
        <Contact />
      </Layout>
    </>
  )
}

export default contact
