import * as React from "react"
import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"
import { UseSiteMetadata } from "../../hooks/useSiteMetadata"

const Contact = () => {
  const { title, siteEmail } = UseSiteMetadata();
  const [data, setData] = React.useState({
    name: "",
    email: "",
    content: ""
  });
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event) => {
    setData((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  const send = async (event) => {
    event.preventDefault();
    setLoading(true);

    fetch("/eapi/send", {
      body: JSON.stringify({
        site: title,
        from: siteEmail,
        name: data.name,
        email: data.email,
        content: data.content
      }),
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST"
    })
      .then((response) => {
        if (response.ok) {
          alert("送信が完了しました！追ってご連絡します！");
          setData({
            name: "",
            email: "",
            content: ""
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <ContactStyles className="section">
      <form name="contact" netlify>
        <input placeholder="お名前" type="text" name="name" value={data.name} onChange={handleChange} />
        <input placeholder="メールアドレス" type="email" name="email" value={data.email} onChange={handleChange} />
        <textarea
          placeholder="内容"
          name="content"
          rows="5"
          value={data.content}
          onChange={handleChange}
        ></textarea>
        {loading ?
          <span>
            送信中...
          </span>
        :
          <span onClick={send} onKeyDown={send} aria-hidden="true">
            <Button text="送信" />
          </span>
        }
      </form>
    </ContactStyles>
  )
}

export default Contact
